
<template>
  <div class="container my-5">
    <div class="row text-center">
      <contact email="zykova@anticorro.cz" name="Ilona Zýková" phone="00420 777 765 429" position="Obchodní oddělení"  >
        <img src="@/assets/image/support.jpeg" alt="Obchodní oddělení">
      </contact>
      <contact email="svihlik@anticorro.cz" name="Jakub Švihlík" phone="00420 777 756 437," position="Vedoucí provozu mořírna"  >
        <img src="@/assets/image/technic.jpeg" alt="oldcar">
      </contact>
      <contact email="mriz@anticorro.cz " name="Ing. Michal Mríz" phone="00420 722 988 575" position="Ředitel společnosti"  >
        <img src="@/assets/image/director.jpeg" alt="ředitel společnosti">
      </contact>
    </div>
  </div>

  <div class="container mt-4">
    <div class="row">
      <!-- Address Column -->
      <div class="col-md-4 column-content border rounded">
        <p>
          QUICKSTEP ANTICORRO s.r.o.<br>
          Dubská 1029<br>
          272 03 Kladno – Dubí
        </p>
      </div>

      <!-- Google Map Column -->
      <div class="col-md-8">
        <div id="map">
          <iframe
              id="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7057.336984722779!2d14.120503833393817!3d50.16038726768924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTDCsDA5JzM5LjIiTiAxNMKwMDcnMjkuMSJF!5e0!3m2!1scs!2sde!4v1572973859636!5m2!1scs!2sde"
              width="600"
              height="450"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from "@/components/Contact.vue";
import supportImage from '@/assets/image/support.jpeg';

export default {
  name: "ContactView",
  components: {Contact}
}
</script>

<style scoped>
  img {
    width: 100%;                 /* Ensure image fills the column width */
    height: 100%;                /* Ensure image fills the column height */
    object-fit: cover;           /* Crop the image while keeping aspect ratio */
  }

  #map {
    height: 100%; /* Make the map take full column height */
    width: 100%;
    min-height: 300px; /* Minimum height for the map */
  }
  .column-content {
    padding: 20px;
  }

  .border {
    --bs-border-color: #C59E47;
  }
</style>