<script setup>

</script>

<template>
  <div class="container">
    <div class="header">
      Napíšte nam : anticorro@anticorro.cz
    </div>
  </div>
  <div class="container">
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid">
        <a class="navbar-brand logo" href="#">
          <img src="@/assets/image/logo2.jpg" alt="Bootstrap">
        </a>

        <!-- Add 'ms-auto' class to push the toggler to the right -->
        <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0" id="navbarontop">
            <li class="nav-item">
              <router-link class="nav-link" to="/" active-class="active">Domov</router-link>
            </li>
<!--            <li class="nav-item">-->
<!--              <router-link class="nav-link" to="/about" active-class="active">About</router-link>-->
<!--            </li>-->
            <li class="nav-item">
              <router-link class="nav-link" to="/contact" active-class="active">Kontakt</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <div class="container">
    <img src="@/assets/image/main-pic2.jpg" alt="Image hero welder">
  </div>
</template>

<style scoped>
.header{
  background-color: #191919;
  letter-spacing: 1px;
  font-family: 'BNR';
  font-size: 12.7px;
  color: #E7E7E7;
}
.logo{
  position: absolute;
  float: left;
  z-index: 999;
  width:124px;
  height: 124px;
  padding-top: 9px;
  margin-left: 60px;
  top: 10px;
}

#navbarSupportedContent {
  margin-left: 230px;
}

#navbarontop {
  padding-top: 17px;
}

#navbarontop > li > a {
  font-family: 'BNR';
  font-size: 17px;
  color: #313943;
  padding-top: 5px;
  padding-bottom: 6px;
  margin-bottom: 22px;
  padding-left: 10px;
  padding-right: 10px!important;
  margin-left: 34px;
  border-bottom: 2px solid rgba(0,0,0,.0);

}

#navbarontop > li >a:hover{
  background-color: none!important;
  background: none!important;
  border-bottom: 2px solid #C59E47;
}

.navbar {
  background-color: white;
}


@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}

.container img {
  max-width: 100%;  /* Scale the image down to fit the width */
  height: auto;     /* Maintain the aspect ratio */
  display: block;   /* Remove the inline element's gap */
}



</style>