

<template>
  <div class="container text-center">
    <div class="row">
      <div class="col-12 col-lg img-container">
        <img src="@/assets/image/abouts2-full.jpeg" alt="oldcar">
      </div>
      <div class="col-12 col-lg">
        <div class="text">
          <h1>QUICKSTEP ANTICORRO s.r.o.</h1>
          <p>
            Společnost navazuje na téměř stoletou tradici výroby a zpracování
            nerezových ocelí společností Poldi Anticorro Kladno. Vyrábíme nerezová zařízení a výrobky pro
            potravinářský, chemický a farmaceutický průmysl. Tato zařízení jsou dodávána podle přání zákazníků
            dle technické dokumentace zpracované našimi projektanty, a to včetně potřebných výpočtů a
            požadovaných atestů. Technologie zpracování se přizpůsobuje požadavkům odběratele na základě
            dlouholetých zkušeností pracovníků společnosti.
          </p>
          <div class="space"></div>
          <router-link class="nav-link" to="/contact" active-class="active">Kontakt</router-link>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "TwoColumnSection"
}
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;             /* Allow wrapping on smaller screens */
}

.col {
  display: flex;
  align-items: stretch;        /* Ensure columns stretch to the same height */
}

.img-container {
  flex-grow: 1;                /* Allow the image container to grow */
  display: flex;
  align-items: center;
  justify-content: center;     /* Center the image if necessary */
}

.img-container img {
  width: 100%;                 /* Ensure image fills the column width */
  height: 100%;                /* Ensure image fills the column height */
  object-fit: cover;           /* Crop the image while keeping aspect ratio */
}

.container h1 {
  font-family: "OpenSans Regular";
  font-size: 19.8px;
  text-align: center;
  color: #5B5B5B;
  margin-top: 5%;
  margin-bottom: 8px;
}
.container a {
  background: #020243;
  border: 1px solid #020243;
  font-family: "OpenSans Regular";
  font-size: 19px;
  color: #fff;
  letter-spacing: 5px;
  width: 68%;
  margin-left: 17%;
  margin-bottom: 26px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}

.text {
  border: 1px solid #C59E47;
  width: 100%;
  height: 100%;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.space {
  flex-grow: 1;
}

.nav-link {
  //margin-top: 20px; /* Adds padding above the link */
  padding-bottom: 10px; /* Adds padding below the link */
}
</style>