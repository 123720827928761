

<template>
  <div class="container">
    <div class="sectiontitle">
      <h1 class="text-center"><span class="dotspace">&bullet;</span>{{ firstPart }}<span class="middlespace">{{secondPart}}</span>&bullet;</h1>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SectionTitle',
    props: {
      firstPart: String,
      secondPart: String
    }
  }
</script>

<style scoped>
.dotspace{
  padding-right: 40px;
}

.middlespace {
  padding-left: 20px;
  padding-right: 40px;
}

.container .sectiontitle > h1 {
  font-family: "OpenSans-Light";
  font-size: 30px;
  padding-top: 46px;
  padding-bottom: 50px;
  color: #020243;
  margin-top: 1%;
}
</style>