<template>
  <div class="home">

  </div>

  <SectionTitle first-part="KDO" second-part="JSME"/>
  <two-column-section/>
  <SectionTitle first-part="NAŠE" second-part="SLUŽBY"/>
  <four-column-section/>


</template>

<script>
import SectionTitle from "@/components/Sectiontitle.vue";
import TwoColumnSection from "@/components/TwoColumnSection.vue";
import FourColumnSection from "@/components/FourColumnSection.vue";

export default {
  name: 'HomeView',
  components: {
    FourColumnSection,
    TwoColumnSection,
    SectionTitle
  }
}
</script>

<style scoped>
</style>
