<script>
export default {
  name: "FourColumnSection"
}
</script>

<template>
  <div class="container text-center">
    <div class="row">
      <div class="col-12 col-lg img-container">
        <img src="@/assets/image/production.jpeg" alt="oldcar">
      </div>
      <div class="col-12 col-lg txt-container">
        <div class="text">
          <h1>Zakázková výroba z nerezu</h1>
          <p>
            Výroba a technické řešení zakázky podle přání a potřeb zákazníka:
            <ul>
              <li>nerezové nádrže</li>
              <li>potrubní díly</li>
              <li>svařence z nerezové oceli</li>
            </ul>
          </p>
          <div class="space"></div>
          <router-link class="nav-link" to="/contact" active-class="active">Kontakt</router-link>
        </div>
      </div>
      <div class="col-12 col-lg img-container">
        <img src="@/assets/image/cleaning.jpeg" alt="oldcar">
      </div>
      <div class="col-12 col-lg txt-container">
        <div class="text">
          <h1>Povrchové úpravy</h1>
          <p>
            Firma nabízí ke svému stávajícímu výrobnímu programu i služby, které
            se týkají povrchových úprav (vnější i vnitřní plochy) různých zařízení a nerezových výrobků:
            <ul>
              <li>moření a pasivování</li>
              <li>broušení celého zařízení, svarů jednostranně nebo oboustranně, na požadovanou drsnost</li>
              <li>leštění na vysoký lesk a elektrochemické leštění</li>
            </ul>
          </p>
          <div class="space"></div>
          <router-link class="nav-link" to="/contact" active-class="active">Kontakt</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.col {
  display: flex;
  align-items: stretch;        /* Ensure columns stretch to the same height */
}

.img-container {
  flex-grow: 1;                /* Allow the image container to grow */
  display: flex;
  align-items: center;
  justify-content: center;     /* Center the image if necessary */
}

.img-container img {
  width: 100%;                 /* Ensure image fills the column width */
  height: 100%;                /* Ensure image fills the column height */
  object-fit: cover;           /* Crop the image while keeping aspect ratio */
}

.txt-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container h1 {
  font-family: "OpenSans Regular";
  font-size: 19.8px;
  text-align: center;
  color: #5B5B5B;
  margin-top: 5%;
  margin-bottom: 8px;
}
.container a {
  background: #020243;
  border: 1px solid #020243;
  font-family: "OpenSans Regular";
  font-size: 19px;
  color: #fff;
  letter-spacing: 5px;
  width: 68%;
  margin-left: 17%;
  margin-bottom: 26px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}

.text {
  border: 1px solid #C59E47;
  width: 100%;
  height: 100%;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text ul {
  padding-top: 5px;
  text-align: justify;
}

.space {
  flex-grow: 1;
}

.nav-link {
  //margin-top: 20px; /* Adds padding above the link */
  padding-bottom: 10px; /* Adds padding below the link */
}
</style>